import { useSpinDelay } from "spin-delay";
import { ClientOnly } from "remix-utils/client-only";
import clsx from "clsx";
import React from "react";
import { H3 } from "~/components/ui/typography";
import { useNavigation } from "@remix-run/react";
import { useUpdateQueryStringValueWithoutNavigation } from "~/utils/hooks";
import {
	getPricingDefaultFilters,
	getPricingData,
	getPricingRegionForProvider,
	getPricingTableData,
	getSignupUrl,
	getPricingAddonForProviderAndRegion,
} from "~/utils/pricing";
import type {
	PricingFilter,
	PricingRelatedData,
	ProductSignupParams,
	PricingProvider,
	Addon,
} from "~/types/product";
import { Select, Option, OptGroup } from "../ui/form/select";
import { Label } from "../ui/form/label";
import { Spacer } from "../ui/spacer";
import groupBy from "lodash/groupBy";
import { Icon } from "../ui/icons";
import { PlanCard, PlanCardSkeleton } from "./plan-card";
import { PlanComparison } from "./plan-comparison";
import { useLocale, useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { MarkDown } from "~/components/markdown";
import { useSearchParams } from "react-router-dom";
import { Alert } from "~/components/alert";
import { spTrackPricing, spTrackWebInteraction } from "~/utils/tracking";
import { type Pricing as PricingSection } from "~/types/sanity-schema";
import { asText } from "~/utils/sanity-helpers";
import { getImageBuilder, getImageProps } from "~/utils/images";
import {
	ServiceSelect,
	ServiceSelectMobile,
	type ProductItemType,
} from "~/route-containers/pricing/service-select";
import { supplant } from "~/utils/misc";

// We will lazy load this component as its only used by our sell team
const PricingTable = React.lazy(() => import("./all-pricing-table"));

interface Props {
	pricingRelatedData: PricingRelatedData;
	variant: PricingSection["variant"];
	defaultCloud: PricingSection["defaultCloud"];
}

export function usePricingFilter(
	pricingRelatedData: PricingRelatedData,
	defaultCloud: PricingSection["defaultCloud"]
) {
	const pricingDefaultFilters = getPricingDefaultFilters({
		cloud: defaultCloud,
	});

	const { pricingData, initialProductId } = pricingRelatedData;

	// Determine visible data based on selected filters
	const {
		data: dataByProvider,
		providers,
		initialProvider,
		initialRegion,
		initialAddon,
	} = getPricingData(pricingData?.plans || [], pricingDefaultFilters);

	const [filters, setFilters] = React.useState<PricingFilter>({
		productId: initialProductId,
		provider: initialProvider,
		region: initialRegion,
		addon: initialAddon,
	});

	// when switch product if provider is not available, use the initial provider
	const selectedPlans =
		dataByProvider[filters.provider]?.[filters.region]?.plans ||
		dataByProvider[initialProvider]?.[initialRegion]?.plans;
	const providerRegions = Object.values(
		dataByProvider[filters.provider] || dataByProvider[initialProvider]
	);

	const providerRegionsGroupedByLocation = groupBy(
		providerRegions,
		({ location }) => location.split(",")[0]
	);

	const addons =
		dataByProvider[filters.provider]?.[filters.region]?.addons ||
		dataByProvider[initialProvider]?.[initialRegion]?.addons;

	function setAddOn(addon: string) {
		setFilters((prev) => ({
			...prev,
			addon,
		}));
	}

	function setProductFilter(
		id: string,
		callback?: (nextState: typeof filters) => void
	) {
		setFilters((prev) => ({
			...prev,
			productId: id,
		}));

		if (callback) {
			callback({
				...filters,
				productId: id,
			});
		}
	}

	function setProviderFilter(
		provider: PricingProvider,
		callback?: (nextState: typeof filters) => void
	) {
		const newRegion = getPricingRegionForProvider(
			dataByProvider,
			provider,
			pricingDefaultFilters
		);

		const newAddon = getPricingAddonForProviderAndRegion(
			dataByProvider,
			provider,
			newRegion
		);

		setFilters((prev) => ({
			...prev,
			provider,
			region: newRegion,
			addon: newAddon,
		}));

		if (callback) {
			callback({
				...filters,
				provider,
				region: newRegion,
			});
		}
	}

	function setRegionFilter(
		region: string,
		callback?: (nextState: typeof filters) => void
	) {
		const newAddon = getPricingAddonForProviderAndRegion(
			dataByProvider,
			filters.provider,
			region
		);

		setFilters((prev) => ({
			...prev,
			region,
			addon: newAddon,
		}));

		if (callback) {
			callback({
				...filters,
				region,
			});
		}
	}

	return {
		filters,
		setProductFilter,
		setProviderFilter,
		setRegionFilter,
		setAddOn,
		selectedPlans,
		providerRegionsGroupedByLocation,
		addons,
		providers,
	};
}

const TieredStorageAddon: Addon = {
	type: "tiered-storage",
	pricePerGB: 0.00012,
};

export function Pricing({
	pricingRelatedData,
	variant = "all-services",
	defaultCloud,
}: Props) {
	const [showPlanComparison, setShowPlanComparison] = React.useState(
		variant === "all-services"
	);

	const {
		filters,
		setProductFilter,
		setProviderFilter,
		setRegionFilter,
		selectedPlans,
		addons,
		providerRegionsGroupedByLocation,
		providers,
	} = usePricingFilter(pricingRelatedData, defaultCloud);

	const locale = useLocale();
	const { t } = useSharedContent(Dictionary.PRICING);

	const navigation = useNavigation();

	const showLoading = useSpinDelay(navigation.state === "loading", {
		delay: 200,
		minDuration: 400,
	});

	const isAllServiceVariant = variant === "all-services";

	const { servicesData, planFeatureData, planComparisonData } =
		pricingRelatedData;

	const [searchParams] = useSearchParams();

	const showAllPlanPricingTable = searchParams.get("plan") === "all";

	const [selectedAddOns, setSelectedAddOns] = React.useState<Array<string>>([]);

	const productsData = servicesData
		.filter(
			(service) => service.externalId != undefined && service.group != undefined
		)
		.map<ProductItemType>((service) => ({
			externalId: asText(service.externalId?.current),
			title: `Aiven for ${asText(service.title)}`,
			iconProps: getImageProps(
				getImageBuilder(service.icon, {
					alt: `Aiven for ${asText(service.title)} logo`,
				})
			),
			group: asText(service.group),
			locale,
			itemUrl: "/pricing",
		}));

	const selectedProduct = React.useMemo(() => {
		return productsData.find(
			(product) => product.externalId === filters.productId
		);
	}, [productsData, filters.productId]);

	const signupParams: ProductSignupParams = {
		service: filters.productId,
		cloud: filters.provider,
	};

	useUpdateQueryStringValueWithoutNavigation("product", filters.productId, {
		enabled: isAllServiceVariant,
	});

	const tableData = getPricingTableData({ plans: selectedPlans });

	function handleProductChange(id: string) {
		setProductFilter(id, () => {
			spTrackPricing({
				selector: "service",
				service: id,
				cloud: filters.provider,
				region: filters.region,
			});
		});
	}

	function handleProviderChange(e: React.FormEvent<HTMLSelectElement>) {
		const newProvider = e.currentTarget.value as PricingProvider;

		setProviderFilter(newProvider, (updatedFilters) => {
			spTrackPricing({
				selector: "cloud",
				service: updatedFilters.productId,
				cloud: updatedFilters.provider,
				region: updatedFilters.region,
			});
		});
	}

	function handleRegionChange(e: React.FormEvent<HTMLSelectElement>) {
		const newRegion = e.currentTarget.value;

		setRegionFilter(newRegion, (updatedFilters) => {
			spTrackPricing({
				selector: "region",
				service: updatedFilters.productId,
				cloud: updatedFilters.provider,
				region: newRegion,
			});
		});
	}

	function renderPricingFilters() {
		return (
			<div className="bg-secondary rounded-md p-6">
				{isAllServiceVariant ? (
					<>
						<div className="mx-auto mb-3 max-w-content text-center">
							<MarkDown
								content={t("serviceTitle", "The complete Aiven platform")}
								fullWidth={true}
							/>
						</div>

						<Label>{t("service", "Service")}</Label>

						<div className="mb-5">
							<>
								<div className="bg-primary border-stroke hidden rounded-md border p-6 md:block">
									<ServiceSelect
										productsData={productsData}
										onChange={handleProductChange}
										value={filters.productId}
									/>
								</div>

								<div className="block md:hidden">
									{
										<ServiceSelectMobile
											productsData={productsData}
											onChange={handleProductChange}
											value={filters.productId}
										/>
									}
								</div>
							</>
						</div>
					</>
				) : null}

				<div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
					<div>
						<Label htmlFor="cloud">{t("cloud", "Cloud")}</Label>
						<Select
							id="cloud"
							value={filters.provider}
							onChange={handleProviderChange}
						>
							{providers.map((provider) => (
								<Option key={provider} value={provider}>
									{provider}
								</Option>
							))}
						</Select>
					</div>

					<div>
						<Label htmlFor="region">{t("region", "Region")}</Label>
						<Select
							id="region"
							value={filters.region}
							onChange={handleRegionChange}
						>
							{Object.keys(providerRegionsGroupedByLocation).map((location) => {
								return (
									<OptGroup key={location} label={location}>
										{providerRegionsGroupedByLocation[location].map(
											({ id, shortId, location }) => (
												<Option
													key={id}
													value={id}
												>{`${location} (${shortId})`}</Option>
											)
										)}
									</OptGroup>
								);
							})}
						</Select>
					</div>
				</div>
			</div>
		);
	}

	function handleAddOnSelect(planGroupName: string, type: string) {
		const addOn = `${planGroupName}-${type}`;

		let updatedAddOns: Array<string> = [];

		if (selectedAddOns.includes(addOn)) {
			updatedAddOns = selectedAddOns.filter(
				(currentAddOn) => currentAddOn !== addOn
			);
		} else {
			updatedAddOns = [...selectedAddOns, addOn];
		}

		setSelectedAddOns(updatedAddOns);

		spTrackPricing({
			selector: "addon",
			service: filters.productId,
			cloud: filters.provider,
			region: filters.region,
			addOns: updatedAddOns,
		});
	}

	function renderPlanCards() {
		const numOfPlans = Object.keys(tableData).length;

		const shouldUseGridLayout = numOfPlans > 1;

		return (
			<ul
				aria-label="pricing-plans"
				className={clsx(
					"gap-5",
					{ "flex justify-center": !shouldUseGridLayout },
					{ "grid grid-cols-1 md:grid-cols-2": shouldUseGridLayout },
					{ "lg:grid-cols-2": numOfPlans === 2 },
					{ "lg:grid-cols-3": numOfPlans === 3 },
					{ "lg:grid-cols-4": numOfPlans === 4 },
					{ "lg:grid-cols-5": numOfPlans === 5 }
				)}
			>
				{Object.entries(tableData).map(([planName, group], index) => {
					const planFeature = planFeatureData
						? planFeatureData.plans.find((plan) => plan.name === planName)
						: undefined;

					const signupUrl = getSignupUrl({
						...signupParams,
						plan: planName,
					});

					const avaialbleAddOns = [...addons];

					// Add tiered storaged to Kafka but not Startup plan
					if (
						(filters.productId === "kafka" ||
							filters.productId === "clickhouse") &&
						!["Startup", "Hobbyist"].includes(planName)
					) {
						avaialbleAddOns.push(TieredStorageAddon);
					}

					return showLoading ? (
						<PlanCardSkeleton
							key={index}
							highLighted={planName === "Business"}
						/>
					) : (
						<li
							key={index}
							aria-label={`plan-${planName}`}
							className={clsx("flex-1", {
								"max-w-[410px]": numOfPlans === 1,
							})}
						>
							<PlanCard
								addons={avaialbleAddOns}
								onAddOnSelect={handleAddOnSelect}
								planGroup={group}
								highLighted={planName === "Business"}
								planFeature={planFeature}
								signupUrl={signupUrl}
								service={filters.productId}
							/>
						</li>
					);
				})}
			</ul>
		);
	}

	function renderPricingFooter() {
		return (
			<div className="text-center [&_p]:!text-sm">
				{filters.productId === "kafka" ? (
					<MarkDown content={t("kpfDisclaimer")} fullWidth={true} />
				) : (
					<MarkDown
						content={supplant(t("pricingCalculatorText"), {
							productId: filters.productId,
						})}
						fullWidth={true}
					/>
				)}
				<MarkDown content={t("contactDisclaimer")} fullWidth={true} />
			</div>
		);
	}

	function renderPlanComparision() {
		if (!planComparisonData || !selectedProduct) {
			return null;
		}

		return (
			<>
				<Spacer size="layout3" />
				<button
					className="mx-auto flex items-center justify-between gap-3 decoration-theme-primary hover:underline"
					onClick={() =>
						setShowPlanComparison((prevOpen) => {
							spTrackWebInteraction({
								object: "compare plans",
								action: "toggle",
								value: !prevOpen ? "open" : "close",
							});

							return !prevOpen;
						})
					}
				>
					<H3 as="span">{t("comparePlans", "Compare plans")}</H3>
					{showPlanComparison ? (
						<Icon
							name="chevron-up"
							color="primary"
							className="h-[16px] w-[16px]"
						/>
					) : (
						<Icon
							name="chevron-down"
							color="primary"
							className="h-[16px] w-[16px]"
						/>
					)}
				</button>

				{showPlanComparison ? (
					<div className="mt-7">
						<PlanComparison
							loading={showLoading}
							product={{
								title: selectedProduct.title,
								iconProps: selectedProduct.iconProps,
							}}
							data={planComparisonData}
							pricingData={tableData}
							signupParams={signupParams}
						/>
						<Spacer size="layout1" />
					</div>
				) : null}
			</>
		);
	}

	function renderAllPlans() {
		return showAllPlanPricingTable ? (
			<>
				<ClientOnly>
					{() => (
						<React.Suspense fallback={<p>Loading pricing ...</p>}>
							<PricingTable plans={selectedPlans} addons={addons} />
						</React.Suspense>
					)}
				</ClientOnly>
				<Spacer size="layout3" />
			</>
		) : null;
	}

	function renderByoaNote() {
		return (
			<Alert
				title={t("byoaTitle")}
				children={
					<div className="[&>*>.markdown-prose>p]:text-secondary [&>*>.markdown-prose>p]:text-sm">
						<MarkDown content={t("byoaDesc")} fullWidth={true} />
					</div>
				}
			/>
		);
	}

	return (
		<>
			{renderPricingFilters()}
			<Spacer size="layout3" />
			{renderAllPlans()}
			{renderPlanCards()}
			<Spacer size="layout3" />
			{renderPricingFooter()}
			{renderPlanComparision()}
			{isAllServiceVariant ? renderByoaNote() : null}
		</>
	);
}
